<template>
  <el-popover trigger="hover" v-model="visible" :width="350" placement="right-start" popper-class="el-popper--isnp" :visible-arrow="false">
    <div class="hp" :class="$i18n.locale">
      <div class="hp-lock" v-if="itemId != item.vehicle.VehicleID" v-loading="true">

      </div>
      <div class="hp-block">
        <p class="hp-block-title hp-block-title-center">
          {{item.vehicle.VehicleName}}
        </p>
      </div>
      <div class="hp-horizontal-split"></div>
      <div class="hp-block">
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.vehicle')}}：</label>
            <p class="hp-block-itemText">{{item.vehicle.VehicleName}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.queryPswd')}}：</label>
            <p class="hp-block-itemText">{{item.vehicle.QueryPwd||'--'}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.bingCount')}}：</label>
            <p class="hp-block-itemText">{{item.deviceList.length}}</p>
          </el-col>
        </el-row>
      </div>
      <div class="hp-horizontal-split"></div>
      <div class="hp-block">
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.owner')}}：</label>
            <p class="hp-block-itemText">{{item.people.PeopleName||'--'}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.ownerMobile')}}：</label>
            <p class="hp-block-itemText">{{item.people.Mobile||'--'}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.ownerAddress')}}：</label>
            <p class="hp-block-itemText">{{item.people.Address||'--'}}</p>
          </el-col>
        </el-row>
      </div>
      <div class="hp-horizontal-split"></div>
      <div class="hp-block">
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.remark')}}：</label>
            <p class="hp-block-itemText">{{item.vehicle.Remark}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.createTime')}}：</label>
            <p class="hp-block-itemText">{{miment(item.vehicle.CreateTime).format()}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.modifier')}}：</label>
            <p class="hp-block-itemText">{{item.modifyInfo.Name}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.updateTime')}}：</label>
            <p class="hp-block-itemText">{{miment(item.vehicle.UpdateTime).format()}}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label class="hp-block-itemTitle">{{$t('popoverInfo.enterprise')}}：</label>
            <p class="hp-block-itemText">{{item.holdInfo.Name}}</p>
          </el-col>
        </el-row>
      </div>
      <div class="hp-block hp-block-bottom" v-if="false">
        查看详情
      </div>
    </div>
    <slot slot="reference"></slot>
  </el-popover>
</template>

<script>
import * as API from '@/api/vehicle'
import miment from 'miment'
export default {
  props: {
    itemId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      miment,
      visible: false,
      item: {
        vehicle: {},
        holdInfo: {},
        deviceList: [],
        people: {},
        modifyInfo: {}
      },
      vehicleType: []
    }
  },
  methods: {
    fetchData() {
      if (!this.itemId) {
        return
      }

      API.QueryVehicleComplex(this.itemId||0).then(ret => {
        this.item = Object.assign({}, ret.data)
        this.vehicleType = JSON.stringify(ret.data.carTypeList).deviceList
        console.log(this.item)
      })
    }
  },
  watch: {
    itemId() {
      //this.fetchData()
    },
    visible(val) {
      if (val && !this.item.vehicle.VehicleID) {
        this.fetchData()
      } else if (val && this.itemId != this.item.vehicle.VehicleID) {
        this.fetchData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$borderColor: #DCDFE6;
$textPrimary: #000;
$textSecondary: #909399;
.hp {
  padding: 0 12px;
  //background-color: #F8F8F8;
  //border: 1px solid $borderColor;
  box-shadow: 0 1px 20px 0 #e4e8eb;
  font-size: 13px;
  position: relative;
  .hp-lock {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    z-index: 1;
  }
  &-block {
    padding: 12px 0;
    &-bottom {
      margin-left: -12px;
      margin-right: -12px;
      border-top: 1px solid $borderColor;
      text-align: center;
    }

    &-title {
      color: $textPrimary;
      font-weight: 700;
      padding-top: 12px;
      &-center {
        text-align: center;
      }
    }
    &-text {

    }

    .el-row {
      padding-left: 12px;
      padding-right: 12px;
      line-height: 32px;
    }
    &-itemTitle {
      float:left;
      width: 5em;
      color: $textPrimary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-itemText {
      margin-left: 5em;
      color: $textSecondary;
      text-align: left;
    }
  }
  &.en &-block {
    &-itemTitle {
      text-align: right;
      width: 7em;
    }
    &-itemText {
      margin-left: 7em;
    }
  }
  &-horizontal-split {
    height: 1px;
    margin: 12px 0;
    background-color: $borderColor;
  }
}
</style>
